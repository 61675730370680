import React, { useState } from "react";

import { Link } from "gatsby";
import Seo from "../components/seo";
import Layout from "../components/layout";
import ToolModal from "../components/tool-modal";
import { ModalRoutingContext } from "gatsby-plugin-modal-routing-3";

const Tool = ({ pageContext }) => {
  const { tool, categories } = pageContext;

  return (
    <>
      <Seo title={tool.name} description={tool.description} />
      <ModalRoutingContext.Consumer>
        {({ modal, closeTo }) => {
          if (modal) {
            return (
              <div className="relative bg-slate-50">
                <Link
                  className={
                    "z-[100] absolute bg-white top-4 left-0 md:top-4 md:h-8 md:w-8 border-2 border-slate-900 rounded-full flex items-center justify-center"
                  }
                  to={closeTo}
                >
                  <svg
                    className="fill-slate-900"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="-6 -6 24 24"
                    width="24"
                    fill="currentColor"
                  >
                    <path d="M7.314 5.9l3.535-3.536A1 1 0 1 0 9.435.95L5.899 4.485 2.364.95A1 1 0 1 0 .95 2.364l3.535 3.535L.95 9.435a1 1 0 1 0 1.414 1.414l3.535-3.535 3.536 3.535a1 1 0 1 0 1.414-1.414L7.314 5.899z"></path>
                  </svg>
                </Link>
                <ToolModal tool={tool} />
              </div>
            );
          } else {
            return (
              <Layout categories={categories}>
                <div className={"mx-4 bg-slate-50"}>
                  <ToolModal tool={tool} />
                </div>
              </Layout>
            );
          }
        }}
      </ModalRoutingContext.Consumer>
    </>
  );
};

export default Tool;
